.Projects {
    text-align: center;
}

.Projects-body {
    background-color: #1a1f16;
    font-size: calc(10px + 2vmin);
    color: white;
    min-height: 100vh;
}

.Projects-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
}

.Back {
    text-decoration: none;
    color: aliceblue;
    text-align: left;
    align-items: start;
}

/* General container styles for projects */
.container {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
    margin-left: 10%;
    margin-right: 10%;
}

.leftColumnA {
    flex: 2;
    margin-right: 20px;
}

.rightColumnA {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.leftColumnB {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.rightColumnB {
    flex: 2;
    margin-right: 20px;
}

.image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

.buttons {
    margin-top: 10px;
}

.button {
    display: inline-block;
    margin-right: 10px;
    padding: 10px 15px;
    background-color: #345830;
    color: #fff;
    text-decoration: none;
    border-radius: 3px;
    border-style: solid;
    border-color: #000000;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #284d24;
}

/* Media query for responsiveness on small screens */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
        align-items: center; /* Center content in column layout */
    }

    .leftColumnA, .leftColumnB {
        margin-right: 0;
        margin-bottom: 20px; /* Adds space between buttons and image */
    }

    .rightColumnA, .rightColumnB {
        justify-content: center; /* Centers the image */
    }
}

/* Fonts */
@font-face {
    font-family: 'JetBrains Mono';
    src: url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff2/JetBrainsMono-Bold-Italic.woff2') format('woff2'),
        url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff/JetBrainsMono-Bold-Italic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff2/JetBrainsMono-Bold.woff2') format('woff2'),
        url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff/JetBrainsMono-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff2/JetBrainsMono-ExtraBold-Italic.woff2') format('woff2'),
        url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff/JetBrainsMono-ExtraBold-Italic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff2/JetBrainsMono-ExtraBold.woff2') format('woff2'),
        url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff/JetBrainsMono-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff2/JetBrainsMono-Italic.woff2') format('woff2'),
        url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff/JetBrainsMono-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff2/JetBrainsMono-Medium-Italic.woff2') format('woff2'),
        url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff/JetBrainsMono-Medium-Italic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff2/JetBrainsMono-Medium.woff2') format('woff2'),
        url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff/JetBrainsMono-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'JetBrains Mono';
    src: url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff2/JetBrainsMono-Regular.woff2') format('woff2'),
        url('https://cdn.jsdelivr.net/gh/JetBrains/JetBrainsMono/web/woff/JetBrainsMono-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
